p{
  font-family: 'Cambay', sans-serif;
  margin: 0;
  padding: 0;
}

.wrapper_nav-image{
  display: flex;
  flex-direction: row;
}

.navIntro{
  border: 15px solid black;
  position: fixed;
  top: 0;
  margin: 5vh 5vw;
  left: -100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 6vh 4vw;
  width: 90vw;
  height: 90vh;
  background-color: rgba(255, 255, 255, 0.99);
  -webkit-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.65);
  -moz-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.65);
  box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.65);
  transition: all 0.5s linear;
  z-index: 10000000000;
}
.intro_closed{
  left: 0;
  transition: all 0.5s ease-in;
}
.background_cover{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 99999999;
  transition: all 0.5s ease-in;
}
.background_cover_close{
  left: 0;
  transition: all 0.5s ease-in;
}

.nav_segments{
  width: 45vw;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  float: left;
  padding-left: 10vh;
}

.nav_segments p{
  transform: scale(1.0);
  transition: all 0.2s linear;
  font-weight: 400;
  cursor: cell;
  padding: 0 0 0 0;
}
.nav_segments p:hover{
  transform: scale(1.1);
  padding: 10px 0;
  font-weight: 700;
  transition: all 0.2s linear;
}
.activenav{
  transform: scale(1.1) !important;
  padding: 10px 0 !important;
  font-weight: 700 !important;
  transition: all 0.2s linear;
}
.nav_segments p a{
  text-decoration: none;
  color: black;
}

.image_container{
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  align-items: flex-start;
  float: right;
  width: 40vw;
}
.image_container img{
  border-radius: 0px;
  width: 100%
}

.bottom_segment{
  padding-top: 0px;
}
.social {
  flex-direction: row;
  box-sizing: border-box;
  color: black;
}
.social a{
  font-size: 30px;
  color: black;
  padding: 0 15px;
  text-decoration: none;
}
.conatct_phone_email{
  padding-top: 20px;
}
.conatct_phone_email a{
  font-size: 45px;
  color: black;
  padding: 0 25px 0 0;
  text-decoration: none;
}
.conatct_phone_email a span {
    font-size: 15px;
    color: black;
    line-height: 45px;
    padding: 0 0 0 10px;
    text-decoration: none;
    vertical-align: bottom;
}
.basic_info{
  padding-top: 30px;
  box-sizing: border-box;
  flex-direction: column;
}
.name_info{
  padding-top: 10px;
  display: flex;
  flex-direction: row;
}
.birthday_location{
  display: flex;
  flex-direction: row;
}
.birthday_location + .birthday_location{
  padding-left: 20px;
}
.birthday_location p + p{
  padding-left: 10px;
}
.birthday_location p i{
  vertical-align: middle;
}
.basic_info h1{
  margin-top: 0;
}
.basic_info p{
  font-size: 20px;
  color: black;
}

.open_nav{
  position: absolute;
  top: 2vw;
  right: -12vw;
  width: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  font-size: 35px;
  transform: rotate(180deg);
  transition: all 0.5s linear;
}

.close_nav{
  position: absolute;
  top: 2vw;
  right: -5vw;
  width: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  font-size: 35px;
  transform: rotate(0deg);
  transition: all 0.5s linear;
}

@media only screen and (max-width: 1199px) {
  .image_container img{
    display: none;
    margin: 0;
  }
  .background_cover{
    width: 100vw;
    height: 100vh;
    top: 0vh;
    left: 0vw;
  }
  .background_cover_close{
    left: 0;
    top: -100vh;
  }
  .navIntro {
    border: 0;
    position: fixed;
    top: 0;
    margin: 0;
    left: 0vw;
    padding: 5px;
    width: 100vw;
    height: 42.5vh;
    background-color: rgba(0, 0, 0, 1);
    transition: all 0.5s linear;
    z-index: 10000000000;
    overflow: hidden;
  }
  .intro_closed{
    height: 10vh;
    left: 0;
    transition: all 0.5s ease-in;
  }
  .close_nav{
    top: 4vh;
    right: 6vw;
    width: 4vw;
    height: 4vw;
    padding: 0;
    font-size: 5vh;
    transform: rotate(-90deg);
    color: white;
    transition: all 0.5s linear;
  }
  .open_nav{
    top: 4vh;
    right: 6vw;
    width: 4vw;
    height: 4vw;
    transform: rotate(90deg);
    transition: all 0.5s linear;
    color: white;
  }
  .nav_segments p{
    transform: scale(1.0);
    transition: all 0.2s linear;
    font-weight: 400;
    line-height: 6vh;
    color: white;
    padding: 0;
  }
  .nav_segments p:hover{
    transform: scale(1.0);
    padding: 0;
    font-weight: normal;
    transition: all 0.2s linear;
  }
  .activenav{
    transform: scale(1.1) !important;
    padding: 0 !important;
    font-weight: 700 !important;
    transition: all 0.2s linear;
  }
  .nav_segments p a{
    color: white;
    line-height: 2vh;
    padding: 0;
  }
  .nav_segments {
    width: 100vw;
    font-size: 24px;
    float: left;
    padding: 1vh;
    position: absolute;
    top: 14vh;
    box-sizing: border-box;
    left: 0;
    border-bottom: 1px solid white;
  }
  .bottom_segment{
    color: white;
    position: absolute;
    top: 34vh;
    padding: 2vh 0;
  }
  .social {
    height: 4vh;
    color: white;
  }
  .social a {
    font-size: 30px;
    color: white;
    padding: 0 15px;
    margin: 1vh 0;
    line-height: 2vh;
    text-decoration: none;
  }
  .basic_info h1 {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2vh;
    margin: 0;
    line-height: 6vh;
    font-size: 4vh;
    width: 50%;
  }
  .conatct_phone_email {
    padding-top: 0;
    position: absolute;
    top: 1vh;
    right: 12vw;
  }
  .conatct_phone_email a {
    font-size: 6vh;
    line-height: 8vh;
    color: white;
    padding: 2vh 2.5vh 0 0;
    text-decoration: none;
    vertical-align: text-bottom;
  }
  .conatct_phone_email a span{
    display: none;
  }
}
