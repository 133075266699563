.workeduction_wrapper{
  width: 96vw;
  float: right;
  height: auto;
  background-color: white;
  position: relative;
}
.vertical_timeline{
  position: fixed;
  left: 52vw;
  transform: translateX(-2px);
  top: 0;
  height: 1000vh;
  width: 4px;
  border-radius: 10px;
  background-color: black;
  z-index: 0;
}

.godina{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 20px 0;
}
.godina p{
  line-height: 30px;
  font-size: 30px;
  color: white;
  background-color: black;
  border-radius: 25px;
  padding: 6px 20px 1px 20px;
  z-index: 100;
}

.info_wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  height: auto;
  overflow: hidden;
}

.single_wrapper{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.single_left{
  padding: 15px 0;
  padding-right: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  box-sizing: border-box;
}
.single_right{
  padding: 15px 0;
  padding-left: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  box-sizing: border-box;
}
.txt_info{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.txt_info p{
  font-size: 18px;
  padding: 0;
  margin: 0;
}
.txt_info p span{
  font-size: 14px;
  padding: 0 0 0 10px;
  margin: 0;
}
.txt_info h5{
  line-height: 25px;
  font-size: 25px;
  padding: 0;
  margin: 0;
}
.txt_info h5 span{
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 10px 0;
  margin: 0;
}
.txt_info h5 span img{
  height: 15px;
  vertical-align: middle;
  padding: 0 5px;
}
.edu_logo{
  padding-right: 25px;
  padding-left: 25px;
}
.edu_logo img{
  max-height: 60px;
}

.txt_exp{
  text-align: left;
}
.txt_edu{
  text-align: right;
}

@media only screen and (max-width: 1200px) {
  .vertical_timeline {
      left: 2vw;
  }
  .workeduction_wrapper {
    width: 100vw;
    background-color: white;
    margin-top: 10vh;
  }
  .godina {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    height: auto;
    margin: 20px 0;
  }
  .godina p {
    line-height: 30px;
    font-size: 30px;
    color: white;
    background-color: black;
    border-radius: 25px;
    padding: 6px 20px 1px 40px;
    z-index: 100;
    margin: -20px;
  }
  .single_wrapper {
    width: 100%;
  }
  .single_right {
    flex-direction: column;
    width: 100%;
    padding: 15px 30px;
  }
  .single_left {
    flex-direction: column;
    width: 100%;
    padding: 15px 30px;
  }
  .info_wrapper {
    flex-direction: column;
  }
  .edu_logo {
    padding-right: 0;
    padding-left: 0;
    display: flex;
    order: 2;
  }
  .txt_edu {
    text-align: left;
  }
  .edu_logo img {
    max-height: 60px;
    float: left;
  }
  .txt_info {
    display: flex;
    order: 1;
    text-align: left;
  }
}
