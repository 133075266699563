.projects{
  width: 94vw;
  float: right;
}

.single_project{
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40vh;
  background-color: white;
  padding: 10vh 2vw 2vh 2vw;
}
.project_info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}
.single_project .project_info h5{
  font-size: 35px;
  line-height: 35px;
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  padding: 0 0 15px 0;
}
.single_project .project_info p{
  font-size: 20px;
  line-height: 20px;
  color: black;
  margin: 0 0 0 0;
  padding: 0 0 15px 0;
  font-style: italic;
}
.single_project .project_info p + p{
  font-size: 16px;
  line-height: 16px;
  color: black;
  margin: 0 0 0 0;
  padding: 0 0 25px 0;
  font-style: normal;
}
.site_link{
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  color: white;
  padding: 10px;
  margin-top: 10px;
  background-color: black;
  transform: scale(1.0);
  transition: all 0.2s linear;
}
.site_link:hover{
  transform: scale(1.1);
  transition: all 0.2s linear;
}

.project_visual{
  position: relative;
  display: flex;
  height: 100%;
  width: 60%;
  box-sizing: border-box;
}
.project_visual img{
  position: absolute;
  max-height: 100%;
  width: auto;
}
.project_visual .gif_item{
  position: static;
  width: 80%;
  padding-left: 10%;
  box-sizing: border-box;
  height: auto;
  display: inline;
}
.genex{
  left: 16%;
}
.usce{
  height: 80%;
  bottom: 0;
  left: 50%;
}
.rudo{
  height: 75%;
  bottom: 0;
  left: 45%;
}
.beogradjanka{
  left: 45%;
}
.toranj{
  left: 12%;
}
.ambasada{
  height: 30%;
  bottom: 0;
  left: 20%;
}
.skupstina{
  height: 28%;
  bottom: 0;
  left: 35%;
}
.msu{
  height: 30%;
  bottom: 0;
  left: 55%;
}

@media only screen and (max-width: 1199px) {
  .single_project {
      flex-direction: column;
      height: 80vh;
      padding: 0;
  }
  .projects{
    margin-top: 14vh;
    width: 100vw;
    float: right;
  }
  .project_info {
    width: 90%;
    margin: 0 5%;
  }
  .single_project .project_info p + p {
    padding: 0 0 15px 0;
  }
  .project_info{
    display: flex;
    order: 2;
  }
  .project_visual {
      -webkit-order: 1;
      order: 1;
      height: 20vh;
      border-bottom: 2px solid black;
      width: 90%;
      margin: 0 5% 5% 5%;
  }
}
