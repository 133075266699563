.skills{
  width: 95vw;
  float: right;
}

.skills h5{
  width: 100%;
  text-align: right;
  margin: 0;
  padding: 20px 40px;
  box-sizing: border-box;
  font-size: 30px;
  font-weight: 700;
}
.groupskills_wrapper{
  width: 100%;
  padding:0 40px 80px 00px;
  box-sizing: border-box;
  overflow: hidden;
}

.single_skill{
  width: 17%;
  float: right;
  margin: 0 1.5%;
  box-sizing: border-box;
  height: 19vh;
  background-color: white;
  position: relative;
}
.single_skill .full_line{
  width: 100%;
  height: 4px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-2px);
  background-color: grey;
  opacity: 0.2;
}
.progress{
  width: 35%;
  height: 4px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-2px);
}
.icon_skill{
  position: absolute;
  height: 50px;
  width: auto;
  top: 50%;
  left: 30%;
  transform: translateY(-25px);
}
.skill_name{
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.single_skill_icon{
  width: 13%;
  float: right;
  margin: 0 1.5% 40px 1.5%;
  box-sizing: border-box;
  height: 19vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.icon_skill_only{
  padding-top: 10px;
  box-sizing: border-box;
  height: 90px;
  width: auto;
  transform: translateY(-25px);
}
.skill_name{
  width: 100%;
  text-align: center;
}


@media only screen and (max-width: 1199px) {
  .skills{
    width: 100vw;
    float: right;
    margin-top: 5vh;
  }
  .skills h5 {
    text-align: center;
    padding: 100px 40px 20px 40px;
  }
  .groupskills_wrapper {
    padding: 0;
  }
  .single_skill {
    width: 80%;
    margin: 0 10%;
  }
  .single_skill_icon {
    width: 30%;
  }
}
