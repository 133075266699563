body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: black;
}

#style-1::-webkit-scrollbar
{
	width: 4px;
	background-color: black;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: black;
}

p{
  font-family: 'Cambay', sans-serif;
  margin: 0;
  padding: 0;
}

.wrapper_nav-image{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.navIntro{
  border: 15px solid black;
  position: fixed;
  top: 0;
  margin: 5vh 5vw;
  left: -100vw;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 6vh 4vw;
  width: 90vw;
  height: 90vh;
  background-color: rgba(255, 255, 255, 0.99);
  box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.65);
  transition: all 0.5s linear;
  z-index: 10000000000;
}
.intro_closed{
  left: 0;
  transition: all 0.5s ease-in;
}
.background_cover{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 99999999;
  transition: all 0.5s ease-in;
}
.background_cover_close{
  left: 0;
  transition: all 0.5s ease-in;
}

.nav_segments{
  width: 45vw;
  color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 35px;
  float: left;
  padding-left: 10vh;
}

.nav_segments p{
  -webkit-transform: scale(1.0);
          transform: scale(1.0);
  transition: all 0.2s linear;
  font-weight: 400;
  cursor: cell;
  padding: 0 0 0 0;
}
.nav_segments p:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  padding: 10px 0;
  font-weight: 700;
  transition: all 0.2s linear;
}
.activenav{
  -webkit-transform: scale(1.1) !important;
          transform: scale(1.1) !important;
  padding: 10px 0 !important;
  font-weight: 700 !important;
  transition: all 0.2s linear;
}
.nav_segments p a{
  text-decoration: none;
  color: black;
}

.image_container{
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  float: right;
  width: 40vw;
}
.image_container img{
  border-radius: 0px;
  width: 100%
}

.bottom_segment{
  padding-top: 0px;
}
.social {
  -webkit-flex-direction: row;
          flex-direction: row;
  box-sizing: border-box;
  color: black;
}
.social a{
  font-size: 30px;
  color: black;
  padding: 0 15px;
  text-decoration: none;
}
.conatct_phone_email{
  padding-top: 20px;
}
.conatct_phone_email a{
  font-size: 45px;
  color: black;
  padding: 0 25px 0 0;
  text-decoration: none;
}
.conatct_phone_email a span {
    font-size: 15px;
    color: black;
    line-height: 45px;
    padding: 0 0 0 10px;
    text-decoration: none;
    vertical-align: bottom;
}
.basic_info{
  padding-top: 30px;
  box-sizing: border-box;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.name_info{
  padding-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.birthday_location{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.birthday_location + .birthday_location{
  padding-left: 20px;
}
.birthday_location p + p{
  padding-left: 10px;
}
.birthday_location p i{
  vertical-align: middle;
}
.basic_info h1{
  margin-top: 0;
}
.basic_info p{
  font-size: 20px;
  color: black;
}

.open_nav{
  position: absolute;
  top: 2vw;
  right: -12vw;
  width: 6vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: auto;
  font-size: 35px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: all 0.5s linear;
}

.close_nav{
  position: absolute;
  top: 2vw;
  right: -5vw;
  width: 4vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: auto;
  font-size: 35px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: all 0.5s linear;
}

@media only screen and (max-width: 1199px) {
  .image_container img{
    display: none;
    margin: 0;
  }
  .background_cover{
    width: 100vw;
    height: 100vh;
    top: 0vh;
    left: 0vw;
  }
  .background_cover_close{
    left: 0;
    top: -100vh;
  }
  .navIntro {
    border: 0;
    position: fixed;
    top: 0;
    margin: 0;
    left: 0vw;
    padding: 5px;
    width: 100vw;
    height: 42.5vh;
    background-color: rgba(0, 0, 0, 1);
    transition: all 0.5s linear;
    z-index: 10000000000;
    overflow: hidden;
  }
  .intro_closed{
    height: 10vh;
    left: 0;
    transition: all 0.5s ease-in;
  }
  .close_nav{
    top: 4vh;
    right: 6vw;
    width: 4vw;
    height: 4vw;
    padding: 0;
    font-size: 5vh;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    color: white;
    transition: all 0.5s linear;
  }
  .open_nav{
    top: 4vh;
    right: 6vw;
    width: 4vw;
    height: 4vw;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: all 0.5s linear;
    color: white;
  }
  .nav_segments p{
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    transition: all 0.2s linear;
    font-weight: 400;
    line-height: 6vh;
    color: white;
    padding: 0;
  }
  .nav_segments p:hover{
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    padding: 0;
    font-weight: normal;
    transition: all 0.2s linear;
  }
  .activenav{
    -webkit-transform: scale(1.1) !important;
            transform: scale(1.1) !important;
    padding: 0 !important;
    font-weight: 700 !important;
    transition: all 0.2s linear;
  }
  .nav_segments p a{
    color: white;
    line-height: 2vh;
    padding: 0;
  }
  .nav_segments {
    width: 100vw;
    font-size: 24px;
    float: left;
    padding: 1vh;
    position: absolute;
    top: 14vh;
    box-sizing: border-box;
    left: 0;
    border-bottom: 1px solid white;
  }
  .bottom_segment{
    color: white;
    position: absolute;
    top: 34vh;
    padding: 2vh 0;
  }
  .social {
    height: 4vh;
    color: white;
  }
  .social a {
    font-size: 30px;
    color: white;
    padding: 0 15px;
    margin: 1vh 0;
    line-height: 2vh;
    text-decoration: none;
  }
  .basic_info h1 {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2vh;
    margin: 0;
    line-height: 6vh;
    font-size: 4vh;
    width: 50%;
  }
  .conatct_phone_email {
    padding-top: 0;
    position: absolute;
    top: 1vh;
    right: 12vw;
  }
  .conatct_phone_email a {
    font-size: 6vh;
    line-height: 8vh;
    color: white;
    padding: 2vh 2.5vh 0 0;
    text-decoration: none;
    vertical-align: text-bottom;
  }
  .conatct_phone_email a span{
    display: none;
  }
}

.workeduction_wrapper{
  width: 96vw;
  float: right;
  height: auto;
  background-color: white;
  position: relative;
}
.vertical_timeline{
  position: fixed;
  left: 52vw;
  -webkit-transform: translateX(-2px);
          transform: translateX(-2px);
  top: 0;
  height: 1000vh;
  width: 4px;
  border-radius: 10px;
  background-color: black;
  z-index: 0;
}

.godina{
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: auto;
  margin: 20px 0;
}
.godina p{
  line-height: 30px;
  font-size: 30px;
  color: white;
  background-color: black;
  border-radius: 25px;
  padding: 6px 20px 1px 20px;
  z-index: 100;
}

.info_wrapper{
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: auto;
  overflow: hidden;
}

.single_wrapper{
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.single_left{
  padding: 15px 0;
  padding-right: 30px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: right;
  box-sizing: border-box;
}
.single_right{
  padding: 15px 0;
  padding-left: 30px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  text-align: left;
  box-sizing: border-box;
}
.txt_info{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.txt_info p{
  font-size: 18px;
  padding: 0;
  margin: 0;
}
.txt_info p span{
  font-size: 14px;
  padding: 0 0 0 10px;
  margin: 0;
}
.txt_info h5{
  line-height: 25px;
  font-size: 25px;
  padding: 0;
  margin: 0;
}
.txt_info h5 span{
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 10px 0;
  margin: 0;
}
.txt_info h5 span img{
  height: 15px;
  vertical-align: middle;
  padding: 0 5px;
}
.edu_logo{
  padding-right: 25px;
  padding-left: 25px;
}
.edu_logo img{
  max-height: 60px;
}

.txt_exp{
  text-align: left;
}
.txt_edu{
  text-align: right;
}

@media only screen and (max-width: 1200px) {
  .vertical_timeline {
      left: 2vw;
  }
  .workeduction_wrapper {
    width: 100vw;
    background-color: white;
    margin-top: 10vh;
  }
  .godina {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    height: auto;
    margin: 20px 0;
  }
  .godina p {
    line-height: 30px;
    font-size: 30px;
    color: white;
    background-color: black;
    border-radius: 25px;
    padding: 6px 20px 1px 40px;
    z-index: 100;
    margin: -20px;
  }
  .single_wrapper {
    width: 100%;
  }
  .single_right {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding: 15px 30px;
  }
  .single_left {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding: 15px 30px;
  }
  .info_wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .edu_logo {
    padding-right: 0;
    padding-left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-order: 2;
            order: 2;
  }
  .txt_edu {
    text-align: left;
  }
  .edu_logo img {
    max-height: 60px;
    float: left;
  }
  .txt_info {
    display: -webkit-flex;
    display: flex;
    -webkit-order: 1;
            order: 1;
    text-align: left;
  }
}

.skills{
  width: 95vw;
  float: right;
}

.skills h5{
  width: 100%;
  text-align: right;
  margin: 0;
  padding: 20px 40px;
  box-sizing: border-box;
  font-size: 30px;
  font-weight: 700;
}
.groupskills_wrapper{
  width: 100%;
  padding:0 40px 80px 00px;
  box-sizing: border-box;
  overflow: hidden;
}

.single_skill{
  width: 17%;
  float: right;
  margin: 0 1.5%;
  box-sizing: border-box;
  height: 19vh;
  background-color: white;
  position: relative;
}
.single_skill .full_line{
  width: 100%;
  height: 4px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  background-color: grey;
  opacity: 0.2;
}
.progress{
  width: 35%;
  height: 4px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.icon_skill{
  position: absolute;
  height: 50px;
  width: auto;
  top: 50%;
  left: 30%;
  -webkit-transform: translateY(-25px);
          transform: translateY(-25px);
}
.skill_name{
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.single_skill_icon{
  width: 13%;
  float: right;
  margin: 0 1.5% 40px 1.5%;
  box-sizing: border-box;
  height: 19vh;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.icon_skill_only{
  padding-top: 10px;
  box-sizing: border-box;
  height: 90px;
  width: auto;
  -webkit-transform: translateY(-25px);
          transform: translateY(-25px);
}
.skill_name{
  width: 100%;
  text-align: center;
}


@media only screen and (max-width: 1199px) {
  .skills{
    width: 100vw;
    float: right;
    margin-top: 5vh;
  }
  .skills h5 {
    text-align: center;
    padding: 100px 40px 20px 40px;
  }
  .groupskills_wrapper {
    padding: 0;
  }
  .single_skill {
    width: 80%;
    margin: 0 10%;
  }
  .single_skill_icon {
    width: 30%;
  }
}

.projects{
  width: 94vw;
  float: right;
}

.single_project{
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 40vh;
  background-color: white;
  padding: 10vh 2vw 2vh 2vw;
}
.project_info{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  width: 40%;
}
.single_project .project_info h5{
  font-size: 35px;
  line-height: 35px;
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  padding: 0 0 15px 0;
}
.single_project .project_info p{
  font-size: 20px;
  line-height: 20px;
  color: black;
  margin: 0 0 0 0;
  padding: 0 0 15px 0;
  font-style: italic;
}
.single_project .project_info p + p{
  font-size: 16px;
  line-height: 16px;
  color: black;
  margin: 0 0 0 0;
  padding: 0 0 25px 0;
  font-style: normal;
}
.site_link{
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  color: white;
  padding: 10px;
  margin-top: 10px;
  background-color: black;
  -webkit-transform: scale(1.0);
          transform: scale(1.0);
  transition: all 0.2s linear;
}
.site_link:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.2s linear;
}

.project_visual{
  position: relative;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 60%;
  box-sizing: border-box;
}
.project_visual img{
  position: absolute;
  max-height: 100%;
  width: auto;
}
.project_visual .gif_item{
  position: static;
  width: 80%;
  padding-left: 10%;
  box-sizing: border-box;
  height: auto;
  display: inline;
}
.genex{
  left: 16%;
}
.usce{
  height: 80%;
  bottom: 0;
  left: 50%;
}
.rudo{
  height: 75%;
  bottom: 0;
  left: 45%;
}
.beogradjanka{
  left: 45%;
}
.toranj{
  left: 12%;
}
.ambasada{
  height: 30%;
  bottom: 0;
  left: 20%;
}
.skupstina{
  height: 28%;
  bottom: 0;
  left: 35%;
}
.msu{
  height: 30%;
  bottom: 0;
  left: 55%;
}

@media only screen and (max-width: 1199px) {
  .single_project {
      -webkit-flex-direction: column;
              flex-direction: column;
      height: 80vh;
      padding: 0;
  }
  .projects{
    margin-top: 14vh;
    width: 100vw;
    float: right;
  }
  .project_info {
    width: 90%;
    margin: 0 5%;
  }
  .single_project .project_info p + p {
    padding: 0 0 15px 0;
  }
  .project_info{
    display: -webkit-flex;
    display: flex;
    -webkit-order: 2;
            order: 2;
  }
  .project_visual {
      -webkit-order: 1;
      order: 1;
      height: 20vh;
      border-bottom: 2px solid black;
      width: 90%;
      margin: 0 5% 5% 5%;
  }
}

